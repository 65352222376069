"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reef REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceEventToJSON = exports.PlaceEventFromJSONTyped = exports.PlaceEventFromJSON = void 0;
const _1 = require("./");
function PlaceEventFromJSON(json) {
    return PlaceEventFromJSONTyped(json, false);
}
exports.PlaceEventFromJSON = PlaceEventFromJSON;
function PlaceEventFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'coordinate': _1.XYZCoordFromJSON(json['coordinate']),
        'tileType': _1.TileTypeFromJSON(json['tile_type']),
    };
}
exports.PlaceEventFromJSONTyped = PlaceEventFromJSONTyped;
function PlaceEventToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'coordinate': _1.XYZCoordToJSON(value.coordinate),
        'tile_type': _1.TileTypeToJSON(value.tileType),
    };
}
exports.PlaceEventToJSON = PlaceEventToJSON;
