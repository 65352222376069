"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reef REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TileTypeToJSON = exports.TileTypeFromJSONTyped = exports.TileTypeFromJSON = exports.TileType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var TileType;
(function (TileType) {
    TileType["SeaTurtle"] = "SeaTurtle";
    TileType["Crab"] = "Crab";
    TileType["Shark"] = "Shark";
    TileType["Dolphin"] = "Dolphin";
    TileType["Starfish"] = "Starfish";
    TileType["Octopus"] = "Octopus";
    TileType["AnglerFish"] = "AnglerFish";
    TileType["Whale"] = "Whale";
})(TileType = exports.TileType || (exports.TileType = {}));
function TileTypeFromJSON(json) {
    return TileTypeFromJSONTyped(json, false);
}
exports.TileTypeFromJSON = TileTypeFromJSON;
function TileTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.TileTypeFromJSONTyped = TileTypeFromJSONTyped;
function TileTypeToJSON(value) {
    return value;
}
exports.TileTypeToJSON = TileTypeToJSON;
