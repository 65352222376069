"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reef REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthNEventToJSON = exports.AuthNEventFromJSONTyped = exports.AuthNEventFromJSON = void 0;
function AuthNEventFromJSON(json) {
    return AuthNEventFromJSONTyped(json, false);
}
exports.AuthNEventFromJSON = AuthNEventFromJSON;
function AuthNEventFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'playerName': json['player_name'],
        'pWord': json['p_word'],
    };
}
exports.AuthNEventFromJSONTyped = AuthNEventFromJSONTyped;
function AuthNEventToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'player_name': value.playerName,
        'p_word': value.pWord,
    };
}
exports.AuthNEventToJSON = AuthNEventToJSON;
