"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reef REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReefApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class ReefApi extends runtime.BaseAPI {
    /**
     * creates a new game room
     */
    hostWaitingRoomRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.hostEvent === null || requestParameters.hostEvent === undefined) {
                throw new runtime.RequiredError('hostEvent', 'Required parameter requestParameters.hostEvent was null or undefined when calling hostWaitingRoom.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/room/host`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.HostEventToJSON(requestParameters.hostEvent),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.WaitingRoomFromJSON(jsonValue));
        });
    }
    /**
     * creates a new game room
     */
    hostWaitingRoom(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.hostWaitingRoomRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * attempts to join a waiting room
     */
    joinWaitingRoomRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.roomId === null || requestParameters.roomId === undefined) {
                throw new runtime.RequiredError('roomId', 'Required parameter requestParameters.roomId was null or undefined when calling joinWaitingRoom.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/room/{roomId}/join`.replace(`{${"roomId"}}`, encodeURIComponent(String(requestParameters.roomId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ReefGameFromJSON(jsonValue));
        });
    }
    /**
     * attempts to join a waiting room
     */
    joinWaitingRoom(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.joinWaitingRoomRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * requests a notification stream of current player\'s completed games.
     */
    listCompleteGamesRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/game/list/complete`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * requests a notification stream of current player\'s completed games.
     */
    listCompleteGames(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.listCompleteGamesRaw(initOverrides);
        });
    }
    /**
     * requests a notification stream of current player\'s active games.
     */
    listGamesRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/game/list`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * requests a notification stream of current player\'s active games.
     */
    listGames(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.listGamesRaw(initOverrides);
        });
    }
    /**
     * requests a notification stream of waiting rooms in the lobby. automatically subscribes authed user to lobby
     */
    listWaitingRoomsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/room/list`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * requests a notification stream of waiting rooms in the lobby. automatically subscribes authed user to lobby
     */
    listWaitingRooms(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.listWaitingRoomsRaw(initOverrides);
        });
    }
    /**
     * authenticates a user. response sets security cookie for further api interactions
     */
    loginRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.authNEvent === null || requestParameters.authNEvent === undefined) {
                throw new runtime.RequiredError('authNEvent', 'Required parameter requestParameters.authNEvent was null or undefined when calling login.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/login`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.AuthNEventToJSON(requestParameters.authNEvent),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * authenticates a user. response sets security cookie for further api interactions
     */
    login(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.loginRaw(requestParameters, initOverrides);
        });
    }
    /**
     * attempts to move a tile
     */
    moveTileRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
                throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling moveTile.');
            }
            if (requestParameters.moveEvent === null || requestParameters.moveEvent === undefined) {
                throw new runtime.RequiredError('moveEvent', 'Required parameter requestParameters.moveEvent was null or undefined when calling moveTile.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/game/{gameId}/move`.replace(`{${"gameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.MoveEventToJSON(requestParameters.moveEvent),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ReefGameFromJSON(jsonValue));
        });
    }
    /**
     * attempts to move a tile
     */
    moveTile(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.moveTileRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * attempts to place a tile at a specific coordinate
     */
    placeTileRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
                throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling placeTile.');
            }
            if (requestParameters.placeEvent === null || requestParameters.placeEvent === undefined) {
                throw new runtime.RequiredError('placeEvent', 'Required parameter requestParameters.placeEvent was null or undefined when calling placeTile.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/game/{gameId}/place`.replace(`{${"gameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.PlaceEventToJSON(requestParameters.placeEvent),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ReefGameFromJSON(jsonValue));
        });
    }
    /**
     * attempts to place a tile at a specific coordinate
     */
    placeTile(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.placeTileRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * registers the client with the websocket server. returns a websocket URL with format <host>/<clientId>
     */
    registerWebsocketRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/register`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InlineResponse200FromJSON(jsonValue));
        });
    }
    /**
     * registers the client with the websocket server. returns a websocket URL with format <host>/<clientId>
     */
    registerWebsocket(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.registerWebsocketRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * drops websocket connection
     */
    unregisterWebsocketRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling unregisterWebsocket.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/register/{clientId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * drops websocket connection
     */
    unregisterWebsocket(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.unregisterWebsocketRaw(requestParameters, initOverrides);
        });
    }
}
exports.ReefApi = ReefApi;
