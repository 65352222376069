"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reef REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FirstPlayerVariantToJSON = exports.FirstPlayerVariantFromJSONTyped = exports.FirstPlayerVariantFromJSON = exports.FirstPlayerVariant = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var FirstPlayerVariant;
(function (FirstPlayerVariant) {
    FirstPlayerVariant["Host"] = "Host";
    FirstPlayerVariant["Guest"] = "Guest";
    FirstPlayerVariant["Random"] = "Random";
})(FirstPlayerVariant = exports.FirstPlayerVariant || (exports.FirstPlayerVariant = {}));
function FirstPlayerVariantFromJSON(json) {
    return FirstPlayerVariantFromJSONTyped(json, false);
}
exports.FirstPlayerVariantFromJSON = FirstPlayerVariantFromJSON;
function FirstPlayerVariantFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.FirstPlayerVariantFromJSONTyped = FirstPlayerVariantFromJSONTyped;
function FirstPlayerVariantToJSON(value) {
    return value;
}
exports.FirstPlayerVariantToJSON = FirstPlayerVariantToJSON;
