"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reef REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompletedGameToJSON = exports.CompletedGameFromJSONTyped = exports.CompletedGameFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function CompletedGameFromJSON(json) {
    return CompletedGameFromJSONTyped(json, false);
}
exports.CompletedGameFromJSON = CompletedGameFromJSON;
function CompletedGameFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'gameId': json['game_id'],
        'name': json['name'],
        'startTimestamp': (new Date(json['start_timestamp'])),
        'endTimestamp': (new Date(json['end_timestamp'])),
        'bluePlayerId': json['blue_player_id'],
        'greenPlayerId': json['green_player_id'],
        'hands': (runtime_1.mapValues(json['hands'], _1.HandFromJSON)),
        'placedTiles': (runtime_1.mapValues(json['placed_tiles'], _1.TileFromJSON)),
        'gameLog': (json['game_log'].map(_1.TurnEntryFromJSON)),
        'playerActions': (runtime_1.mapValues(json['player_actions'], _1.XYZCoordArrayFromJSON)),
        'winner': json['winner'],
    };
}
exports.CompletedGameFromJSONTyped = CompletedGameFromJSONTyped;
function CompletedGameToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'game_id': value.gameId,
        'name': value.name,
        'start_timestamp': (value.startTimestamp.toISOString().substr(0, 10)),
        'end_timestamp': (value.endTimestamp.toISOString().substr(0, 10)),
        'blue_player_id': value.bluePlayerId,
        'green_player_id': value.greenPlayerId,
        'hands': (runtime_1.mapValues(value.hands, _1.HandToJSON)),
        'placed_tiles': (runtime_1.mapValues(value.placedTiles, _1.TileToJSON)),
        'game_log': (value.gameLog.map(_1.TurnEntryToJSON)),
        'player_actions': (runtime_1.mapValues(value.playerActions, _1.XYZCoordArrayToJSON)),
        'winner': value.winner,
    };
}
exports.CompletedGameToJSON = CompletedGameToJSON;
