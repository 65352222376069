"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reef REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.HostEventToJSON = exports.HostEventFromJSONTyped = exports.HostEventFromJSON = void 0;
const _1 = require("./");
function HostEventFromJSON(json) {
    return HostEventFromJSONTyped(json, false);
}
exports.HostEventFromJSON = HostEventFromJSON;
function HostEventFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'gameName': json['game_name'],
        'firstPlayerVariant': _1.FirstPlayerVariantFromJSON(json['first_player_variant']),
        'gameVariant': _1.GameVariantFromJSON(json['game_variant']),
    };
}
exports.HostEventFromJSONTyped = HostEventFromJSONTyped;
function HostEventToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'game_name': value.gameName,
        'first_player_variant': _1.FirstPlayerVariantToJSON(value.firstPlayerVariant),
        'game_variant': _1.GameVariantToJSON(value.gameVariant),
    };
}
exports.HostEventToJSON = HostEventToJSON;
