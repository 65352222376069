"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reef REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.WinStateToJSON = exports.WinStateFromJSONTyped = exports.WinStateFromJSON = exports.WinState = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var WinState;
(function (WinState) {
    WinState["Todo"] = "TODO";
})(WinState = exports.WinState || (exports.WinState = {}));
function WinStateFromJSON(json) {
    return WinStateFromJSONTyped(json, false);
}
exports.WinStateFromJSON = WinStateFromJSON;
function WinStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.WinStateFromJSONTyped = WinStateFromJSONTyped;
function WinStateToJSON(value) {
    return value;
}
exports.WinStateToJSON = WinStateToJSON;
