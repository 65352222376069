"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reef REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InlineResponse200ToJSON = exports.InlineResponse200FromJSONTyped = exports.InlineResponse200FromJSON = void 0;
const runtime_1 = require("../runtime");
function InlineResponse200FromJSON(json) {
    return InlineResponse200FromJSONTyped(json, false);
}
exports.InlineResponse200FromJSON = InlineResponse200FromJSON;
function InlineResponse200FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'url': json['url'],
        'playerId': !runtime_1.exists(json, 'player_id') ? undefined : json['player_id'],
        'playerName': !runtime_1.exists(json, 'player_name') ? undefined : json['player_name'],
    };
}
exports.InlineResponse200FromJSONTyped = InlineResponse200FromJSONTyped;
function InlineResponse200ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'url': value.url,
        'player_id': value.playerId,
        'player_name': value.playerName,
    };
}
exports.InlineResponse200ToJSON = InlineResponse200ToJSON;
