"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reef REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TileToJSON = exports.TileFromJSONTyped = exports.TileFromJSON = void 0;
const _1 = require("./");
function TileFromJSON(json) {
    return TileFromJSONTyped(json, false);
}
exports.TileFromJSON = TileFromJSON;
function TileFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tileType': _1.TileTypeFromJSON(json['tile_type']),
        'playerId': json['player_id'],
        'coordinate': _1.XYZCoordFromJSON(json['coordinate']),
    };
}
exports.TileFromJSONTyped = TileFromJSONTyped;
function TileToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tile_type': _1.TileTypeToJSON(value.tileType),
        'player_id': value.playerId,
        'coordinate': _1.XYZCoordToJSON(value.coordinate),
    };
}
exports.TileToJSON = TileToJSON;
