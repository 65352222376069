"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reef REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TurnEntryToJSON = exports.TurnEntryFromJSONTyped = exports.TurnEntryFromJSON = void 0;
const _1 = require("./");
function TurnEntryFromJSON(json) {
    return TurnEntryFromJSONTyped(json, false);
}
exports.TurnEntryFromJSON = TurnEntryFromJSON;
function TurnEntryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'playerId': json['player_id'],
        'playTimestamp': (new Date(json['play_timestamp'])),
        'from': json['from'],
        'to': _1.XYZCoordFromJSON(json['to']),
    };
}
exports.TurnEntryFromJSONTyped = TurnEntryFromJSONTyped;
function TurnEntryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'player_id': value.playerId,
        'play_timestamp': (value.playTimestamp.toISOString().substr(0, 10)),
        'from': value.from,
        'to': _1.XYZCoordToJSON(value.to),
    };
}
exports.TurnEntryToJSON = TurnEntryToJSON;
