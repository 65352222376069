"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reef REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameVariantToJSON = exports.GameVariantFromJSONTyped = exports.GameVariantFromJSON = exports.GameVariant = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GameVariant;
(function (GameVariant) {
    GameVariant["BaseGame"] = "BaseGame";
    GameVariant["FirstExpansion"] = "FirstExpansion";
    GameVariant["SecondExpansion"] = "SecondExpansion";
    GameVariant["ThirdExpansion"] = "ThirdExpansion";
})(GameVariant = exports.GameVariant || (exports.GameVariant = {}));
function GameVariantFromJSON(json) {
    return GameVariantFromJSONTyped(json, false);
}
exports.GameVariantFromJSON = GameVariantFromJSON;
function GameVariantFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GameVariantFromJSONTyped = GameVariantFromJSONTyped;
function GameVariantToJSON(value) {
    return value;
}
exports.GameVariantToJSON = GameVariantToJSON;
