"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reef REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.WaitingRoomToJSON = exports.WaitingRoomFromJSONTyped = exports.WaitingRoomFromJSON = void 0;
const _1 = require("./");
function WaitingRoomFromJSON(json) {
    return WaitingRoomFromJSONTyped(json, false);
}
exports.WaitingRoomFromJSON = WaitingRoomFromJSON;
function WaitingRoomFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'roomId': json['room_id'],
        'hostId': json['host_id'],
        'hostName': json['host_name'],
        'gameName': json['game_name'],
        'firstPlayerVariant': _1.FirstPlayerVariantFromJSON(json['first_player_variant']),
        'gameVariant': _1.GameVariantFromJSON(json['game_variant']),
    };
}
exports.WaitingRoomFromJSONTyped = WaitingRoomFromJSONTyped;
function WaitingRoomToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'room_id': value.roomId,
        'host_id': value.hostId,
        'host_name': value.hostName,
        'game_name': value.gameName,
        'first_player_variant': _1.FirstPlayerVariantToJSON(value.firstPlayerVariant),
        'game_variant': _1.GameVariantToJSON(value.gameVariant),
    };
}
exports.WaitingRoomToJSON = WaitingRoomToJSON;
